ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
Spinner = require 'components/common/spinner'
{ isAcpFeaturesOn } = require 'stores/utils/etd_template_helper'
{ getOrganizationName } = require 'lib/app_utils'
{ Visualization } = VIComponents

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    isPreparingData: Store.isPreparingData()
    visualizationData: Store.getVisualizationDataForPreview()

VisualizationPreview = createReactClass
  displayName: 'VisualizationPreview'

  propTypes:
    itemId: PropTypes.string.isRequired
    isPreparingData: PropTypes.bool.isRequired
    visualizationData: PropTypes.instanceOf(Immutable.Map).isRequired

  componentDidMount: ->
    ACPVisualGuidelinesActions.prepareDataForVisualizationPreview(@props.itemId)

  render: ->
    {
      itemId,
      isPreparingData,
      visualizationData
    } = @props

    if isPreparingData
      return <Spinner />
    else
      not visualizationData.isEmpty() && <Visualization
        i18n={i18next}
        data={visualizationData.toJS()}
        config={
          organizationName: getOrganizationName()
          printout: false,
          graphScales: {
            rr: [0, 2],
            ard: [-100, 100],
            md: [-2, 2],
            smd: [-2, 2]
          }
        }
      />

module.exports = ConnectStore VisualizationPreview, [ACPVisualGuidelinesStore], storeConnector
