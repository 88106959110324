module.exports = function () {
  return {
    "assessment": {
      "additionalConsiderations": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "criteria": {
        "acceptability": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervention est-elle acceptable pour les principaux intervenants?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Y a-t-il des intervenants principaux qui n’accepteraient pas la distribution des avantages, des préjudices et des coûts?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Y a-t-il des intervenants principaux qui n’accepteraient pas les coûts ou les effets indésirables à court terme pour les effets souhaitables (avantages) à l’avenir?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Y a-t-il des intervenants principaux qui ne seraient pas d’accord avec les valeurs liées aux effets souhaitables ou indésirables (en raison de la façon dont elles pourraient être affectées personnellement ou en raison de leurs perceptions de l’importance relative des effets pour les autres)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "L’intervention (option) nuirait-elle à l’autonomie des personnes?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Existe-t-il des intervenants principaux qui désapprouveraient moralement l’intervention (option), pour des raisons autres que ses effets sur l’autonomie des personnes (c’est-à-dire en relation avec des principes éthiques tels que la non-malfaisance, la bienfaisance ou la justice)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Les principaux intervenants sont-ils susceptibles de ne pas accepter la répartition des avantages, des préjudices et des coûts; ou les coûts ou les effets indésirables à court terme pour les effets (avantages) souhaitables à l'avenir? Sont-ils susceptibles d’être en désaccord avec les valeurs attachées aux effets souhaitables ou indésirables, ou de ne pas accepter l’intervention diagnostique en raison de préoccupations éthiques?",
          "options": [
            {
              "text": "Non",
              "value": "no"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Oui",
              "value": "yes"
            }
          ],
          "ratingDescription": "Quelle intervention est plus acceptable pour les principaux intervenants?",
          "ratingOptions": ["best_acceptability", "intermediate_acceptability", "worst_acceptability"],
          "selectedOption": ""
        },
        "additionalSection": {
          "additionalOptions": [
            {
              "text": "Jugement supplémentaire 1",
              "value": "additionalOption_1"
            },
            {
              "text": "Jugement supplémentaire 2",
              "value": "additionalOption_2"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Description du critère personnalisé",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Fournir une info-bulle personnalisée",
          "options": [
            {
              "text": "Jugement 1",
              "value": "option_1"
            },
            {
              "text": "Jugement 2",
              "value": "option_2"
            },
            {
              "text": "Jugement 3",
              "value": "option_3"
            },
            {
              "text": "Jugement 4",
              "value": "option_4"
            },
            {
              "text": "Jugement 5",
              "value": "option_5"
            }
          ],
          "selectedOption": ""
        },
        "balanceOfEffects": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'équilibre entre les effets souhaitables et indésirables favorise- t-il l'intervention ou la comparaison?",
          "details": {
            "additionalInfos": [
              {
                "criteriaAnswers": [
                  {
                    "criterionId": "values"
                  },
                  {
                    "criterionId": "certaintyOfEvidence"
                  },
                  {
                    "criterionId": "desirableEffects"
                  },
                  {
                    "criterionId": "undesirableEffects"
                  }
                ],
                "description": "Les jugements détaillés pour ce critère comprennent les jugements concernant chacun des quatre critères précédents:"
              },
              {
                "additions": ["À quel point les gens valorisent-ils moins les résultats futures par rapport aux résultats actuels (leurs taux d'actualisation)?", "Attitudes des gens envers les effets indésirables (degré d’aversion au risque).", "Attitudes des gens envers les effets souhaitables (à quel point ils sont des preneurs de risque)"],
                "description": "En plus, les groupes d'experts pourraient vouloir considérer (et, le cas échéant, documenter) la mesure dans laquelle les considérations suivantes pourraient influencer l'équilibre entre les effets souhaitables et indésirables:"
              }
            ],
            "panelDiscussion": ""
          },
          "info": "Quel est l'équilibre entre les effets souhaitables et indésirables, en tenant compte de la valeur que les individus accordent aux principaux résultats, de l'importance des effets souhaitables et indésirables, de la certitude de ces estimations, des taux d'actualisation, de l'aversion au risque et de la recherche du risque?",
          "options": [
            {
              "text": "Favorise la comparaison",
              "value": "favors_comparison"
            },
            {
              "text": "Favorise probablement la comparaison",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Ne favorise ni l'intervention ni la comparaison",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Favorise probablement l'intervention",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Favorise l'intervention",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          "ratingOptions": ["best_balance", "intermediate", "worst_balance"],
          "selectedOption": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'équilibre entre les effets sanitaires souhaitables et indésirables favorise-t-il l'intervention ou la comparaison?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Est-ce que l'efficacité <strong> à court et à long terme</strong> (dans des circonstances contrôlées, souvent idéales) ou <strong> l'efficacité </strong> (dans un contexte réel) de l'intervention sur la <strong> santé des personnes</strong>, y compris les résultats rapportés par les patients, favorise l'intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L’efficacité <strong>à court et à long terme</strong> ou <strong>l’impact</strong> de l’intervention sur la <strong>santé de la population</strong>, y compris sur les résultats déclarés par les bénéficiaires, favorisent-ils l’intervention ou la comparaison? (Cela devrait inclure des considérations permettant de déterminer si les résultats au niveau de la population représentent les résultats agrégés au niveau individuel ou émergent de la dynamique du système)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La mesure dans laquelle<strong>les patients/bénéficiaires valorisent</strong>les différents résultats de santé, favorise-t-elle l’intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "La <strong>probabilité et la gravité des effets indésirables</strong> associés à l’intervention (y compris le risque que l'intervention soit utilisée à mauvais escient) favorisent-elles l’intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Les <strong>impacts positifs ou négatifs généraux liés à la santé</strong> (par exemple, la réduction de la stigmatisation, l'impact positif sur d'autres maladies, les effets de débordement au-delà des patients/bénéficiaires) associés à l’intervention favorisent-ils l’intervention ou la comparaison?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus le bénéfice net pour la santé associé à une intervention est grand, plus la probabilité d'une recommandation générale en faveur de cette intervention est grande.",
          "options": [
            {
              "text": "Favorise la comparaison",
              "value": "favors_the_comparison"
            },
            {
              "text": "Favorise probablement la comparaison",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Ne favorise ni l'intervention ni la comparaison",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Favorise probablement l'intervention",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Favorise l'intervention",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEffects": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la certitude globale des données probantes des effets du test?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Dans quelle mesure la recherche fournit-elle une bonne indication des effets probables dans tous les résultats critiques; c'est-à-dire la probabilité que les effets soient suffisamment différents de ce que la recherche a révélé pour que cela puisse affecter une décision concernant l'intervention diagnostique?",
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidence": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la certitude globale des données probantes des effets?",
          "details": {
            "panelDiscussion": ""
          },
          "info": "Dans quelle mesure la recherche fournit-elle une bonne indication des effets probables dans tous les résultats critiques; c'est-à-dire la probabilité que les effets soient suffisamment différents de ce que la recherche a révélé pour que cela puisse affecter une décision concernant l'intervention?",
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "ratingDescription": "What is the overall certainty of the evidence of effects for each intervention?",
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la certitude globale des données probantes des effets de la gestion qui est guidée par les résultats du test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la certitude des donnée probantes relatives aux ressources requises (coûts)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Tous les éléments qui sont importants dans l'utilisation des ressources et qui peuvent différer entre les options envisagées, ont-ils été identifiés?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Incertitude importante",
                    "value": "important"
                  },
                  {
                    "text": "Possible incertitude importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probablement pas d'incertitude importante",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Pas d'incertitude importante",
                    "value": "no_important"
                  }
                ],
                "question": "Quel est le degré de certitude du coût des éléments de l'utilisation des ressources et qui diffèrent entre les options envisagées?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Variabilité importante",
                    "value": "important"
                  },
                  {
                    "text": "Possible variabilité importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probablement pas de variabilité importante",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Pas de variabilité importante",
                    "value": "no_important"
                  }
                ],
                "question": "Y a-t-il une variabilité importante du coût des éléments de l'utilisation des ressources qui diffère entre les options envisagées?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Quel est le degré de certitude des données probantes reliées à la différence pour chaque type d'utilisation des ressources (par exemple, médicaments, hospitalisations) et du coût des ressources?",
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la certitude globale des données probantes de la fiabilité des tests?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la certitude globale des données probantes de tous les avantages directs critiques ou importants, les effets indésirables ou la charge du test?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalOptions": [
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quel est le degré de certitude du lien entre les résultats du test et les décisions de gestion?",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Elevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "costEffectiveness": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Aucune étude incluse",
              "value": "no_included_studies"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Le rapport coût-efficacité de l'intervention favorise-t-il l'intervention ou la comparaison?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Aucune étude incluse",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Le rapport coût-efficacité est-il sensible aux analyses de sensibilité unidirectionnelles?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Aucune étude incluse",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Le rapport coût-efficacité est-il sensible à l'analyse de sensibilité à variables multiples?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Aucune étude incluse",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Limites très sérieuses",
                    "value": "very_serious"
                  },
                  {
                    "text": "Limites sérieuses",
                    "value": "serious"
                  },
                  {
                    "text": "Aucune limite sérieuse",
                    "value": "no_serious"
                  }
                ],
                "question": "L'évaluation économique sur laquelle se base l'estimation du rapport coût-efficacité est-elle fiable?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Aucune étude incluse",
                    "value": "no_included_studies"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Limites très sérieuses",
                    "value": "very_serious"
                  },
                  {
                    "text": "Limites sérieuses",
                    "value": "serious"
                  },
                  {
                    "text": "Aucune limite sérieuse",
                    "value": "no_serious"
                  }
                ],
                "question": "L'évaluation économique sur laquelle se base l'estimation du rapport coût-efficacité est-elle applicable au(x) contexte(s) d'intérêt?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "L’intervention est-elle rentable, compte tenu de l’incertitude ou de la variabilité des coûts, de l’incertitude ou la variabilité du bénéfice net, les analyses de sensibilité et la fiabilité et l’applicabilité de l’évaluation économique?",
          "options": [
            {
              "text": "Favorise la comparaison",
              "value": "favors_comparison"
            },
            {
              "text": "Favorise probablement la comparaison",
              "value": "probably_favors_comparison"
            },
            {
              "text": "Ne favorise ni l'intervention ni la comparaison",
              "value": "doesnt_favor_any"
            },
            {
              "text": "Favorise probablement l'intervention",
              "value": "probably_favors_intervention"
            },
            {
              "text": "Favorise l'intervention",
              "value": "favors_intervention"
            }
          ],
          "ratingDescription": "Quelle intervention la rentabilité favorise-t-elle?",
          "ratingOptions": ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"],
          "selectedOption": ""
        },
        "desirableEffects": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est l'importance des effets souhaitables anticipés?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Insignifiants",
                    "value": "trivial"
                  },
                  {
                    "text": "Petit",
                    "value": "small"
                  },
                  {
                    "text": "Modérés",
                    "value": "moderate"
                  },
                  {
                    "text": "Elevés",
                    "value": "large"
                  }
                ],
                "question": "Quelle est l’importance de l’effet anticipé (différence) pour chaque résultat principal pour lequel il existe un effet souhaitable?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Quelle est l'ampleur des effets souhaitables de l'intervention en tenant compte de l’importance des résultats (combien ils sont valorisés) et de l’ampleur de l’effet (la probabilité de bénéficier d'un avantage ou le degré d'amélioration que les individus seraient susceptibles d'éprouver)?",
          "options": [
            {
              "text": "Insignifiants",
              "value": "trivial"
            },
            {
              "text": "Petit",
              "value": "small"
            },
            {
              "text": "Modérés",
              "value": "moderate"
            },
            {
              "text": "Elevés",
              "value": "large"
            }
          ],
          "ratingDescription": "How substantial are the desirable anticipated effects for each intervention?",
          "ratingOptions": ["most_effective", "intermediate_effectiveness", "least_effective"],
          "selectedOption": ""
        },
        "equity": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quel serait l'impact sur l'équité en matière de santé?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Y a-t-il des groupes ou des contextes qui pourraient être défavorisés par rapport au problème ou aux options envisagés?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Existe-t-il des raisons plausibles pour anticiper les différences dans l'efficacité relative de l'option pour les groupes ou contextes défavorisés?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Existe-t-il des conditions de base différentes selon les groupes ou les contextes qui affectent l'efficacité absolue de l'option ou l'importance du problème pour les groupes ou les contextes défavorisés?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Y a-t-il des considérations importantes qui devraient être prises en compte lors de l'exécution de l’intervention (option) afin de s’assurer de la réduction des inégalités, si c'est possible, et que ces inégalités ne soient pas accrues?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Existe-t-il des raisons plausibles pour anticiper les différences dans l'efficacité relative de l'intervention pour les sous-groupes défavorisés ou les différentes conditions de base entre les sous-groupes défavorisés qui affectent l'efficacité absolue de l'intervention ou l'importance du problème?",
          "options": [
            {
              "text": "Réduite",
              "value": "reduced"
            },
            {
              "text": "Probablement réduite",
              "value": "probably_reduced"
            },
            {
              "text": "Probablement pas d'impact",
              "value": "probably_no_impact"
            },
            {
              "text": "Probablement augmentée",
              "value": "probably_increased"
            },
            {
              "text": "Augmentée",
              "value": "increased"
            }
          ],
          "ratingDescription": "si elle est recommandée, quelle intervention réduirait le plus les inégalités en matière de santé?",
          "ratingOptions": ["most_reduction", "intermediate_reduction", "less_reduction"],
          "selectedOption": ""
        },
        "feasibility": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervention est-elle réalisable pour la mise en œuvre?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "L’intervention (option) est-elle durable?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Existe-t-il des obstacles importants qui sont susceptibles de limiter la faisabilité de la mise en œuvre de l'intervention (option) ou de nécessiter des considération lors de sa mise en œuvre?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Est-il possible de maintenir l'utilisation de l'intervention diagnostique et de surmonter les obstacles potentiels à son utilisation?",
          "options": [
            {
              "text": "Non",
              "value": "no"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Oui",
              "value": "yes"
            }
          ],
          "ratingDescription": "Quelle intervention est plus réalisable à mettre en œuvre?",
          "ratingOptions": ["most_feasible", "intermediate_feasibility", "least_feasible"],
          "selectedOption": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervention est-elle réalisable pour la mise en œuvre?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Existe-t-il des <strong> barrières juridiques </strong> qui pourraient limiter la faisabilité de la mise en œuvre de l'intervention?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Oui",
                    "value": "yes"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Non",
                    "value": "no"
                  }
                ],
                "question": "Y a-t-il des <strong> aspects de gouvernance </strong> (par exemple, des considérations stratégiques, des décisions antérieures) qui peuvent limiter la faisabilité de la mise en œuvre de l'intervention? (Cela devrait inclure des considérations concernant la présence ou l'absence d'institutions formelles ou d'informations qui peuvent assurer un leadership, une supervision et une responsabilité efficaces dans la mise en œuvre de l'intervention et influencer la faisabilité de la mise en œuvre).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Implications bénéfiques importantes",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Implications bénéfiques modérées",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Implications bénéfiques et néfastes négligeables",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Implications néfastes modérées",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implications néfastes importantes",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Quelles sont les <strong> implications </strong> de l'interaction de l’intervention<strong> et de l’adaptation de l’intervention avec le système de santé existant?</strong>(Cela inclut les considérations concernant l’interaction de l’intervention avec le système de santé existant et ses composantes ou son impact sur celles-ci?)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Implications bénéfiques importantes",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Implications bénéfiques modérées",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Implications bénéfiques et néfastes négligeables",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Implications néfastes modérées",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implications néfastes importantes",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Quelles sont les <strong>implications</strong> de l’intervention <strong>pour les personnels de santé et les ressources humaines en général</strong> (dans le domaine de la santé ou dans d’autres domaines? (Cela devrait inclure des considérations concernant la nécessité, l'utilisation et l'impact sur les personnels de santé et sur les autres ressources humaines, ainsi que leurs répartitions)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Implications bénéfiques importantes",
                    "value": "large_beneficial_implications"
                  },
                  {
                    "text": "Implications bénéfiques modérées",
                    "value": "moderate_beneficial_implications"
                  },
                  {
                    "text": "Implications bénéfiques et néfastes négligeables",
                    "value": "negligible_beneficial_and_adverse_implications"
                  },
                  {
                    "text": "Implications néfastes modérées",
                    "value": "moderate_adverse_implications"
                  },
                  {
                    "text": "Implications néfastes importantes",
                    "value": "large_adverse_implications"
                  }
                ],
                "question": "Quelles sont les <strong>implications</strong> de l'intervention <strong>pour l'infrastructure du système de santé et l'infrastructure générale</strong>? (Cela devrait inclure des considérations relatives à la nécessité, à l’utilisation et à l’impact sur les ressources matérielles et sur l’infrastructure, ainsi qu’à leurs distributions)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus la faisabilité d'une option du point de vue de toutes ou de la plupart des parties prenantes est grande, plus la probabilité d'une recommandation générale en faveur de l'intervention est grande. Plus les implications sont avantageuses pour l'ensemble du système de santé, plus la probabilité d'une recommandation générale en faveur de l'intervention est grande.",
          "options": [
            {
              "text": "Non",
              "value": "no"
            },
            {
              "text": "Probablement non",
              "value": "probably_not"
            },
            {
              "text": "Incertain",
              "value": "uncertain"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Oui",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "feasible": {
          "description": "to identify and measure, and not susceptible to manipulation",
          "options": [],
          "ratingOptions": ["very_feasible", "feasible", "least_feasible"],
          "selectedOptions": ""
        },
        "financialAndEconomicConsiderations": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Les considérations financières et économiques favorisent-elles l’intervention ou la comparaison?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Les impacts <strong>du coût et du budget</strong> de la mise en œuvre et du maintien de l’intervention favorisent-ils l’intervention ou la comparaison? (Cela devrait inclure des considérations sur la façon dont les impacts du coût et du budget varient à court et à long terme.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L’impact <strong>global de l’intervention sur l’économie</strong> favorise-t-il l’intervention ou la comparaison? (Cela devrait inclure des considérations sur la façon dont les différents types d'impact économique sont répartis entre les différents secteurs ou niveaux organisationnels, si l'intervention contribue ou limite la réalisation d'objectifs plus généraux de développement et de réduction de la pauvreté, et sur la façon dont cela affecte la main-d'œuvre disponible.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "Le <strong> rapport coûts/bénéfices</strong> (par exemple basé sur des estimations de coût-efficacité, du coût-bénéfice ou du coût-utilité) favorise-t-il l'intervention ou la comparaison?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus les implications financières et économiques d'une intervention sont avantageuses, plus la probabilité d'une recommandation générale en faveur de cette intervention est grande.",
          "options": [
            {
              "text": "Favorise la comparaison",
              "value": "favors_the_comparison"
            },
            {
              "text": "Favorise probablement la comparaison",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Ne favorise ni l'intervention ni la comparaison",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Favorise probablement l'intervention",
              "value": "probably_favor_the_intervention"
            },
            {
              "text": "Favorise l'intervention",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quel serait l'impact de l'intervention sur l’équité, l’égalité et la non-discrimination en matière de santé?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "L'intervention est-elle susceptible d'augmenter les inégalités existantes et/ou les inégalités dans l'état de santé ou ses déterminants? (Cela devrait inclure des considérations sur les changements probables des inégalités au fil du temps, par exemple, si les augmentations initiales sont susceptibles de s’équilibrer au fil du temps, à mesure que l’intervention est intensifiée?)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Les avantages et les préjudices de l’intervention sont-ils susceptibles d’être répartis d'une manière équitable? (Cela devrait inclure un accent particulier sur les implications pour les groupes de population vulnérables, marginalisés ou socialement défavorisés.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "L'intervention est-elle abordable et, par conséquent, financièrement accessible pour les groupes de population touchés? (Cela devrait inclure l'impact sur les dépenses de santé des ménages, y compris le risque de dépenses de santé catastrophiques et les risques financiers liés à la santé.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "L'intervention est-elle accessible parmi les groupes de population touchés? (Cela devrait inclure des considérations concernant l'accès physique et informationnel.)",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "L'intervention vise-t-elle une affection particulièrement grave (par exemple potentiellement mortelle, en fin de vie, touchant des personnes ayant un faible état de santé préexistant) ou rare?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "L’intervention représente-t-elle la seule option disponible? (Cela devrait inclure des considérations pour savoir si l’intervention est proportionnée au besoin et si elle fera l’objet d’un examen périodique.)",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus la probabilité que l’intervention augmente l’équité et/ou l’égalité en matière de santé et qu’elle réduise la discrimination à l’égard d’un groupe particulier est grande, plus la probabilité d’une recommandation générale en faveur de cette intervention est grande.",
          "options": [
            {
              "text": "Négatif",
              "value": "negative"
            },
            {
              "text": "Probablement négatif",
              "value": "probably_negative"
            },
            {
              "text": "Ni négatif ni positif",
              "value": "neither_negative_nor_positive"
            },
            {
              "text": "Probablement positif",
              "value": "probably_positive"
            },
            {
              "text": "Positif",
              "value": "positive"
            }
          ],
          "selectedOption": ""
        },
        "humanRights": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L’intervention est-elle conforme aux normes et aux principes universels des droits de l'homme?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Toutes les recommandations devraient être conformes aux normes et aux principes universels des droits de l'homme.",
          "options": [
            {
              "text": "Non",
              "value": "no"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Incertain",
              "value": "uncertain"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Oui",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "problem": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Le problème est-il une priorité?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Les conséquences du problème sont-elles sérieuses (c'est-à-dire sévères ou importantes en terme d'avantages ou d'économies potentiels)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Le problème est-il urgent?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Non",
                    "value": "no"
                  },
                  {
                    "text": "Probablement non",
                    "value": "probably_no"
                  },
                  {
                    "text": "Probablement oui",
                    "value": "probably_yes"
                  },
                  {
                    "text": "Oui",
                    "value": "yes"
                  }
                ],
                "question": "Est-ce une priorité reconnue (par exemple, basée sur une décision politique ou une politique générale)?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus un problème est sérieux ou urgent, plus il est probable que l'option qui aborde le problème sera prioritaire",
          "options": [
            {
              "text": "Non",
              "value": "no"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Oui",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "qualityOfEvidence": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est la qualité globale des données probantes?",
          "details": {
            "panelDiscussion": "",
            "questions": [],
            "questionsType": "general"
          },
          "info": "Plus la qualité des données probantes pour les différents critères du cadre WHO-INTEGRATE est élevée, plus la probabilité d'une recommandation générale est élevée.",
          "options": [
            {
              "text": "Très faible",
              "value": "very_low"
            },
            {
              "text": "Faible",
              "value": "low"
            },
            {
              "text": "Modérée",
              "value": "moderate"
            },
            {
              "text": "Élevée",
              "value": "high"
            }
          ],
          "selectedOption": ""
        },
        "relevantDirect": {
          "description": "to the selected problem and in the field of application",
          "options": [],
          "ratingOptions": ["very_relevant", "relevant", "least_relevant"],
          "selectedOptions": ""
        },
        "resourcesRequired": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est l'ampleur des ressources requises (coûts)?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Insignifiantes",
                    "value": "trivial"
                  },
                  {
                    "text": "Petit",
                    "value": "small"
                  },
                  {
                    "text": "Modérées",
                    "value": "moderate"
                  },
                  {
                    "text": "Élevées",
                    "value": "large"
                  }
                ],
                "question": "Quelle est l’ampleur de la différence dans chaque élément de ressource pour lequel moins de ressources sont nécessaires?",
                "resources": []
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Élevées",
                    "value": "large"
                  },
                  {
                    "text": "Modérées",
                    "value": "moderate"
                  },
                  {
                    "text": "Petit",
                    "value": "small"
                  },
                  {
                    "text": "Insignifiantes",
                    "value": "trivial"
                  }
                ],
                "question": "Quelle est l’ampleur de la différence dans chaque élément de l’utilisation des ressources pour lequel plus de ressources sont nécessaires?",
                "resources": []
              }
            ],
            "questionsType": "resources"
          },
          "info": "Quelle est l’importance du coût de la différence dans l’utilisation des ressources entre l’intervention et la comparaison?",
          "options": [
            {
              "text": "Coûts importants",
              "value": "large_costs"
            },
            {
              "text": "Coûts modérés",
              "value": "moderate_costs"
            },
            {
              "text": "Coûts et économies négligeables",
              "value": "negligible_costs_savings"
            },
            {
              "text": "Économies modérées",
              "value": "moderate_savings"
            },
            {
              "text": "Économies importantes",
              "value": "large_savings"
            }
          ],
          "ratingDescription": "How large are the resource requirements (costs) for each intervention?",
          "ratingOptions": ["less_costs", "intermediate_costs", "most_costs"],
          "selectedOption": ""
        },
        "responsive": {
          "description": "sensitive to change that means they should be able to capture possible changes in the system",
          "options": [],
          "ratingOptions": ["very_responsive", "responsive", "least_responsive"],
          "selectedOptions": ""
        },
        "scientificallySound": {
          "description": "based on evidence (GRADE criteria) and strongly correlated with the quality of care provided",
          "options": [],
          "ratingOptions": ["very_scientifically_sound", "scientifically_sound", "least_scientifically_sound"],
          "selectedOptions": ""
        },
        "societalImplications": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'équilibre entre les implications sociétales souhaitables et indésirables favorise- t-il l'intervention ou la comparaison?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L’impact social et les conséquences sociales de l’intervention (tels que l'augmentation ou la réduction de la stigmatisation, les résultats scolaires, la cohésion sociale ou la réalisation de divers droits humains au-delà de la santé) favorisent-ils l’intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L’impact environnemental et les implications pour la durabilité écologique (par exemple, protection des ressources naturelles, atténuation ou adaptation au changement climatique) favorisent-ils l’intervention ou la comparaison?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus le bénéfice sociétal net associé à une intervention est grand, plus la probabilité d'une recommandation générale en faveur de cette intervention est grande.",
          "options": [
            {
              "text": "Favorise la comparaison",
              "value": "favors_the_comparison"
            },
            {
              "text": "Favorise probablement la comparaison",
              "value": "probably_favors_the_comparison"
            },
            {
              "text": "Ne favorise ni l'intervention ni la comparaison",
              "value": "does_not_favor_either_the_intervention_or_the_comparison"
            },
            {
              "text": "Favorise probablement l'intervention",
              "value": "probably_favors_the_intervention"
            },
            {
              "text": "Favorise l'intervention",
              "value": "favors_the_intervention"
            }
          ],
          "selectedOption": ""
        },
        "socioCulturalAcceptability": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "L'intervention est-elle acceptable pour les principaux intervenants?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Elevée",
                    "value": "large"
                  },
                  {
                    "text": "Modérée",
                    "value": "moderate"
                  },
                  {
                    "text": "Petit",
                    "value": "small"
                  },
                  {
                    "text": "Insignifiante",
                    "value": "trivial"
                  }
                ],
                "question": "Quelle est l’importance de l’intrusion de l’intervention, en termes de violation, sur les libertés individuelles (y compris l'intimité et la dignité)? (L'intrusivité varie entre insignifiante - par exemple en permettant le choix (par exemple la construction de pistes cyclables) à élevée - par exemple en limitant ou en éliminant le choix (par exemple l'interdiction des cigarettes)).",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Elevée",
                    "value": "large"
                  },
                  {
                    "text": "Modérée",
                    "value": "moderate"
                  },
                  {
                    "text": "Petit",
                    "value": "small"
                  },
                  {
                    "text": "Insignifiante",
                    "value": "trivial"
                  }
                ],
                "question": "Dans quelle mesure l’impact de l’intervention sur l’autonomie des personnes, des groupes de population et/ou des organisations est-elle importante (en ce qui concerne leur capacité à prendre une décision compétente, éclairée et volontaire)?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'acceptabilité socioculturelle de l'intervention parmi les bénéficiaires visés favorise-t-elle l'intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'acceptabilité socioculturelle de l'intervention parmi les personnes destinées à mettre en œuvre l'intervention, favorise-t-elle l'intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'acceptabilité socioculturelle de l'intervention parmi les autres groupes de parties prenantes concernés, favorise-t-elle l'intervention ou la comparaison?",
                "selectedOption": ""
              },
              {
                "additionalOptions": [
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Favorise la comparaison",
                    "value": "favors_the_comparison"
                  },
                  {
                    "text": "Favorise probablement la comparaison",
                    "value": "probably_favors_the_comparison"
                  },
                  {
                    "text": "Ne favorise ni l'intervention ni la comparaison",
                    "value": "does_not_favor_either_the_intervention_or_the_comparison"
                  },
                  {
                    "text": "Favorise probablement l'intervention",
                    "value": "probably_favors_the_intervention"
                  },
                  {
                    "text": "Favorise l'intervention",
                    "value": "favors_the_intervention"
                  }
                ],
                "question": "L'acceptabilité socioculturelle de l'intervention auprès du grand public favorise-t-elle l'intervention ou la comparaison?",
                "selectedOption": ""
              }
            ],
            "questionsType": "general"
          },
          "info": "Plus l’acceptabilité socioculturelle d’une intervention pour toutes ou pour la plupart des parties prenantes concernées est grande, plus la probabilité d’une recommandation générale en faveur de cette intervention est grande.",
          "options": [
            {
              "text": "Non",
              "value": "no"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Incertain",
              "value": "uncertain"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Oui",
              "value": "yes"
            }
          ],
          "selectedOption": ""
        },
        "testAccuracy": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est l'exactitude du test? ",
          "details": {
            "panelDiscussion": ""
          },
          "options": [
            {
              "text": "Très imprécis",
              "value": "very_inaccurate"
            },
            {
              "text": "Imprécis",
              "value": "inaccurate"
            },
            {
              "text": "Précis",
              "value": "accurate"
            },
            {
              "text": "Très précis",
              "value": "very_accurate"
            }
          ],
          "selectedOption": ""
        },
        "undesirableEffects": {
          "additionalOptions": [
            {
              "text": "Varie",
              "value": "varies"
            },
            {
              "text": "Ignore",
              "value": "dont_know"
            }
          ],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Quelle est l'importance des effets indésirables anticipés?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "additionalOptions": [
                  {
                    "text": "Varie",
                    "value": "varies"
                  },
                  {
                    "text": "Ignore",
                    "value": "dont_know"
                  }
                ],
                "options": [
                  {
                    "text": "Elevés",
                    "value": "large"
                  },
                  {
                    "text": "Modérés",
                    "value": "moderate"
                  },
                  {
                    "text": "Petit",
                    "value": "small"
                  },
                  {
                    "text": "Insignifiants",
                    "value": "trivial"
                  }
                ],
                "question": "Quelle est l'importance de l'effet anticipé (différence) pour chaque résultat principale pour laquelle il existe un effet indésirable?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Quelle est l'ampleur des effets indésirables de l'intervention en tenant compte de l'importance des résultats (combien ils sont valorisés) et de l’ampleur de l’effet (la probabilité de bénéficier d'un avantage ou le degré d'amélioration que les individus seraient susceptibles d'éprouver)?",
          "options": [
            {
              "text": "Elevés",
              "value": "large"
            },
            {
              "text": "Modérés",
              "value": "moderate"
            },
            {
              "text": "Petit",
              "value": "small"
            },
            {
              "text": "Insignifiants",
              "value": "trivial"
            }
          ],
          "ratingDescription": "How substantial are the undesirable anticipated effects for each intervention?",
          "ratingOptions": ["least_harmful", "intermediate", "more_harmful"],
          "selectedOption": ""
        },
        "values": {
          "additionalOptions": [],
          "decision": {
            "comments": "",
            "selectedOption": ""
          },
          "description": "Y a-t-il une incertitude ou une variabilité importantes quant à la valeur que les personnes accordent aux principaux résultats?",
          "details": {
            "panelDiscussion": "",
            "questions": [
              {
                "options": [
                  {
                    "text": "Incertitude importante",
                    "value": "important"
                  },
                  {
                    "text": "Possible incertitude importante",
                    "value": "probably_important"
                  },
                  {
                    "text": "Probablement pas d'incertitude importante",
                    "value": "probably_no_important"
                  },
                  {
                    "text": "Pas d'incertitude importante",
                    "value": "no_important"
                  }
                ],
                "question": "Y a-t-il une incertitude importante sur la valeur que les gens accordent aux principales résultats?",
                "selectedOptions": {}
              }
            ],
            "questionsType": "outcomes"
          },
          "info": "Dans quelle mesure les individus valorisent-ils chacun des principaux results? L'incertitude quant à l'importance qu'ils accordent à chacun des résultats ou la variabilité de l'importance que les différents individus accordent aux résultats sont-ils suffisamment importants pour entraîner des décisions différentes ?",
          "options": [
            {
              "text": "Incertitude ou variabilité importantes",
              "value": "important_uncertainty"
            },
            {
              "text": "Incertitude ou variabilité éventuellement importantes",
              "value": "possible_important"
            },
            {
              "text": "Probablement pas d'incertitude ou de variabilité importantes",
              "value": "probably_no_important"
            },
            {
              "text": "Pas d'incertitude ou de variabilité importantes",
              "value": "no_important"
            }
          ],
          "selectedOption": ""
        }
      },
      "researchEvidences": {
        "acceptability": {
          "content": ""
        },
        "additionalSection": {
          "content": ""
        },
        "balanceOfEffects": {
          "content": ""
        },
        "balanceOfHealthBenefitsAndHarms": {
          "content": ""
        },
        "certaintyOfEffects": {
          "content": ""
        },
        "certaintyOfEvidence": {
          "content": ""
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestEffects": {
          "content": ""
        },
        "certaintyOfEvidenceOfTestResult": {
          "content": ""
        },
        "costEffectiveness": {
          "content": ""
        },
        "desirableEffects": {
          "content": ""
        },
        "equity": {
          "content": ""
        },
        "feasibility": {
          "content": ""
        },
        "feasibilityAndHealthSystemConsiderations": {
          "content": ""
        },
        "feasible": {
          "content": ""
        },
        "financialAndEconomicConsiderations": {
          "content": ""
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "content": ""
        },
        "humanRights": {
          "content": ""
        },
        "problem": {
          "content": ""
        },
        "qualityOfEvidence": {
          "content": ""
        },
        "relevantDirect": {
          "content": ""
        },
        "resourcesRequired": {
          "content": ""
        },
        "responsive": {
          "content": ""
        },
        "scientificallySound": {
          "content": ""
        },
        "societalImplications": {
          "content": ""
        },
        "socioCulturalAcceptability": {
          "content": ""
        },
        "testAccuracy": {
          "content": ""
        },
        "undesirableEffects": {
          "content": ""
        },
        "values": {
          "content": ""
        }
      },
      "sections": {
        "acceptability": {
          "additionalConsiderationId": "acceptability",
          "criterionId": "acceptability",
          "name": "Acceptabilité",
          "researchEvidenceId": "acceptability"
        },
        "additionalSection": {
          "additionalConsiderationId": "additionalSection",
          "criterionId": "additionalSection",
          "name": "Critère personnalisé",
          "researchEvidenceId": "additionalSection"
        },
        "balanceOfEffects": {
          "additionalConsiderationId": "balanceOfEffects",
          "criterionId": "balanceOfEffects",
          "name": "Équilibre des effets",
          "researchEvidenceId": "balanceOfEffects"
        },
        "balanceOfHealthBenefitsAndHarms": {
          "additionalConsiderationId": "balanceOfHealthBenefitsAndHarms",
          "criterionId": "balanceOfHealthBenefitsAndHarms",
          "name": "Équilibre entre les avantages et les préjudices",
          "researchEvidenceId": "balanceOfHealthBenefitsAndHarms"
        },
        "certaintyOfEffects": {
          "additionalConsiderationId": "certaintyOfEffects",
          "criterionId": "certaintyOfEffects",
          "name": "Certitude des effets",
          "researchEvidenceId": "certaintyOfEffects"
        },
        "certaintyOfEvidence": {
          "additionalConsiderationId": "certaintyOfEvidence",
          "criterionId": "certaintyOfEvidence",
          "name": "Certitude des données probantes",
          "researchEvidenceId": "certaintyOfEvidence"
        },
        "certaintyOfEvidenceOfManagementEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
          "criterionId": "certaintyOfEvidenceOfManagementEffects",
          "name": "Certitude des données probantes des effets de la gestion",
          "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
        },
        "certaintyOfEvidenceOfRequiredResources": {
          "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
          "criterionId": "certaintyOfEvidenceOfRequiredResources",
          "name": "Certitude des donnée probantes des ressources requises",
          "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
        },
        "certaintyOfEvidenceOfTestAccuracy": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
          "criterionId": "certaintyOfEvidenceOfTestAccuracy",
          "name": "Certitude des données probantes de la fiabilité des tests",
          "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
        },
        "certaintyOfEvidenceOfTestEffects": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
          "criterionId": "certaintyOfEvidenceOfTestEffects",
          "name": "Certitude des données probantes des effets du test",
          "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
        },
        "certaintyOfEvidenceOfTestResult": {
          "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
          "criterionId": "certaintyOfEvidenceOfTestResult",
          "name": "Certitude des données probantes du résultat du test/de la gestion",
          "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
        },
        "costEffectiveness": {
          "additionalConsiderationId": "costEffectiveness",
          "criterionId": "costEffectiveness",
          "name": "Rentabilité",
          "researchEvidenceId": "costEffectiveness"
        },
        "desirableEffects": {
          "additionalConsiderationId": "desirableEffects",
          "criterionId": "desirableEffects",
          "name": "Effets Souhaitables",
          "researchEvidenceId": "desirableEffects"
        },
        "equity": {
          "additionalConsiderationId": "equity",
          "criterionId": "equity",
          "name": "Équité",
          "researchEvidenceId": "equity"
        },
        "feasibility": {
          "additionalConsiderationId": "feasibility",
          "criterionId": "feasibility",
          "name": "Faisabilité",
          "researchEvidenceId": "feasibility"
        },
        "feasibilityAndHealthSystemConsiderations": {
          "additionalConsiderationId": "feasibilityAndHealthSystemConsiderations",
          "criterionId": "feasibilityAndHealthSystemConsiderations",
          "name": "Considérations relatives à la faisabilité et au système de santé",
          "researchEvidenceId": "feasibilityAndHealthSystemConsiderations"
        },
        "feasible": {
          "additionalConsiderationId": "feasible",
          "criterionId": "feasible",
          "name": "FEASIBLE",
          "researchEvidenceId": "feasible"
        },
        "financialAndEconomicConsiderations": {
          "additionalConsiderationId": "financialAndEconomicConsiderations",
          "criterionId": "financialAndEconomicConsiderations",
          "name": "Considérations financières et économiques",
          "researchEvidenceId": "financialAndEconomicConsiderations"
        },
        "healthEquityEqualityAndNonDiscrimination": {
          "additionalConsiderationId": "healthEquityEqualityAndNonDiscrimination",
          "criterionId": "healthEquityEqualityAndNonDiscrimination",
          "name": "Équité, égalité et non-discrimination en matière de santé",
          "researchEvidenceId": "healthEquityEqualityAndNonDiscrimination"
        },
        "humanRights": {
          "additionalConsiderationId": "humanRights",
          "criterionId": "humanRights",
          "name": "Droits humains",
          "researchEvidenceId": "humanRights"
        },
        "problem": {
          "additionalConsiderationId": "problem",
          "criterionId": "problem",
          "name": "Problème",
          "researchEvidenceId": "problem"
        },
        "qualityOfEvidence": {
          "additionalConsiderationId": "qualityOfEvidence",
          "criterionId": "qualityOfEvidence",
          "name": "Qualité des données probantes",
          "researchEvidenceId": "qualityOfEvidence"
        },
        "relevantDirect": {
          "additionalConsiderationId": "relevantDirect",
          "criterionId": "relevantDirect",
          "name": "RELEVANT (DIRECT)",
          "researchEvidenceId": "relevantDirect"
        },
        "resourcesRequired": {
          "additionalConsiderationId": "resourcesRequired",
          "criterionId": "resourcesRequired",
          "name": "Resources requises",
          "researchEvidenceId": "resourcesRequired"
        },
        "responsive": {
          "additionalConsiderationId": "responsive",
          "criterionId": "responsive",
          "name": "RESPONSIVE",
          "researchEvidenceId": "responsive"
        },
        "scientificallySound": {
          "additionalConsiderationId": "scientificallySound",
          "criterionId": "scientificallySound",
          "name": "SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)",
          "researchEvidenceId": "scientificallySound"
        },
        "societalImplications": {
          "additionalConsiderationId": "societalImplications",
          "criterionId": "societalImplications",
          "name": "Implications sociétales",
          "researchEvidenceId": "societalImplications"
        },
        "socioCulturalAcceptability": {
          "additionalConsiderationId": "socioCulturalAcceptability",
          "criterionId": "socioCulturalAcceptability",
          "name": "Acceptabilité socioculturelle",
          "researchEvidenceId": "socioCulturalAcceptability"
        },
        "testAccuracy": {
          "additionalConsiderationId": "testAccuracy",
          "criterionId": "testAccuracy",
          "name": "Fiabilité des tests",
          "researchEvidenceId": "testAccuracy"
        },
        "undesirableEffects": {
          "additionalConsiderationId": "undesirableEffects",
          "criterionId": "undesirableEffects",
          "name": "Effets Indésirables",
          "researchEvidenceId": "undesirableEffects"
        },
        "values": {
          "additionalConsiderationId": "values",
          "criterionId": "values",
          "name": "Valeurs",
          "researchEvidenceId": "values"
        }
      },
      "sectionsOrder": []
    },
    "conclusions": {
      "sections": {
        "benefits": {
          "content": "",
          "description": "Avantages"
        },
        "clearAndActionable": {
          "content": "",
          "description": "Claire et actionnable",
          "options": [
            {
              "text": "Oui",
              "value": "yes"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Non",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "clinicalConsiderations": {
          "content": "",
          "description": "Clinical considerations"
        },
        "collectingAndSummarizing": {
          "content": "",
          "description": "La collecte et la synthèse des données probantes constituent une mauvaise utilisation des ressources limitées et de l’énergie du groupe d’experts des lignes directrices (coût d’opportunité en gros).",
          "options": [
            {
              "text": "Oui",
              "value": "yes"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Non",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "considerations": {
          "content": "",
          "description": "Considérations"
        },
        "contextAndSystemConsiderations": {
          "content": "",
          "description": "Considérations relatives au contexte et au système"
        },
        "decision": {
          "content": "",
          "description": "Décision",
          "options": [
            {
              "text": "Mise en œuvre complète",
              "value": "full_implementation"
            },
            {
              "text": "Évaluation de l'impact",
              "value": "impact_evaluation"
            },
            {
              "text": "Étude Pilote",
              "value": "pilot_study"
            },
            {
              "text": "Reportez",
              "value": "postpone"
            },
            {
              "text": "Ne mettez pas en œuvre",
              "value": "do_not_implement"
            }
          ]
        },
        "decisionTypeCoverage": {
          "content": "",
          "description": "Type de décision",
          "options": [
            {
              "direction": "negative",
              "text": "Ne pas couvrir",
              "value": "do_not_cover"
            },
            {
              "direction": "positive",
              "text": "Couvrir avec le développement des données probantes",
              "value": "cover_with_evidence"
            },
            {
              "direction": "positive",
              "text": "Couvrir avec la négociation des prix",
              "value": "cover_with_price"
            },
            {
              "direction": "positive",
              "text": "Couverture Limitée",
              "value": "restricted_coverage"
            },
            {
              "direction": "positive",
              "text": "Couvrir",
              "value": "cover"
            }
          ],
          "selectedOption": ""
        },
        "decisionTypeImplementation": {
          "content": "",
          "description": "Type de décision",
          "options": [
            {
              "direction": "negative",
              "text": "Ne pas mettre en œuvre l'option",
              "value": "do_not_implement"
            },
            {
              "direction": "none",
              "text": "Reporter la décision",
              "value": "postpone"
            },
            {
              "direction": "none",
              "text": "Mener une étude pilote de l’option",
              "value": "conduct_pilot_study"
            },
            {
              "direction": "positive",
              "text": "Mettre en œuvre l'option avec une évaluation d'impact",
              "value": "implement_with_evaluation"
            },
            {
              "direction": "positive",
              "text": "Mettre en œuvre l'option",
              "value": "implement"
            }
          ],
          "selectedOption": ""
        },
        "evidence": {
          "content": "",
          "description": "Preuves"
        },
        "frequencyDoseDuration": {
          "content": "",
          "description": "Fréquence/Dose/Durée"
        },
        "goodPracticeStatement": {
          "content": "",
          "description": "Déclaration de bonne pratique"
        },
        "harms": {
          "content": "",
          "description": "Préjudices"
        },
        "implementationConsiderations": {
          "content": "",
          "description": "Considérations relatives à la mise en œuvre",
          "details": {
            "content": "",
            "description": "Mise en œuvre détaillée"
          }
        },
        "implementationConsiderationsOfAllRelevantOutcomes": {
          "content": "",
          "description": "Après la prise en considération de toutes les issues cliniques pertinentes et des conséquences potentielles en aval, la mise en œuvre de la déclaration relative aux bonnes pratiques entraîne d’importantes conséquences positives nettes.",
          "options": [
            {
              "text": "Oui",
              "value": "yes"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Non",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "implementationConsiderationsOrRemarks": {
          "content": "",
          "description": "Considérations relatives à la mise en œuvre",
          "options": [
            {
              "text": "Oui",
              "value": "yes"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Non",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "indications": {
          "content": "",
          "description": "Indications"
        },
        "indicationsForDiscontinuation": {
          "content": "",
          "description": "Indications pour l'arrêt du traitement"
        },
        "justification": {
          "content": "",
          "description": "Justification",
          "details": {
            "description": "Justification détaillée"
          },
          "overallDescription": "Justification globale"
        },
        "keyMessage": {
          "content": "",
          "description": "Message Clé"
        },
        "limitations": {
          "content": "",
          "description": "Limites"
        },
        "mdgRecommendation": {
          "content": "",
          "description": "Recommandation",
          "options": [
            {
              "direction": "positive",
              "text": "Recommandé",
              "value": "recommend"
            },
            {
              "direction": "negative",
              "text": "Non recommandé",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Sometimes Recommended",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Pas de recommandation",
              "value": "suggest_either"
            }
          ],
          "selectedOption": ""
        },
        "monitoringAndEvaluation": {
          "content": "",
          "description": "Suivi et évaluation",
          "details": {
            "content": "",
            "description": "Suivi et évaluation détaillés"
          }
        },
        "monitoringAndEvaluationQI": {
          "content": "",
          "description": "Suivi et évaluation"
        },
        "multipleChronicConditionsAndPolypharmacy": {
          "content": "",
          "description": "Multiples Conditions chroniques et Polypharmacie"
        },
        "multipleRecommendations": {
          "description": "Recommandation(s)",
          "options": [
            {
              "direction": "negative",
              "text": "Recommandation forte contre l’intervention",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Recommandation conditionnelle contre l'intervention",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Recommandation conditionnelle soit pour l’intervention ou pour la comparaison",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Recommandation conditionnelle pour l'intervention",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Recommandation forte pour l’intervention",
              "value": "recommend"
            }
          ],
          "recommendations": [
            {
              "content": "",
              "selectedOption": ""
            }
          ]
        },
        "rationale": {
          "content": "",
          "description": "Justification"
        },
        "rationaleConnectingIndirectEvidence": {
          "content": "",
          "description": "Il existe une justification bien documentée, claire et explicite qui relie les effets probantes indirectes",
          "options": [
            {
              "text": "Oui",
              "value": "yes"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Non",
              "value": "no"
            }
          ],
          "selectedOption": ""
        },
        "recommendation": {
          "content": "",
          "description": "Recommandation"
        },
        "recommendationTypeIntervention": {
          "content": "",
          "description": "Type de recommandation",
          "options": [
            {
              "direction": "negative",
              "text": "Recommandation forte contre l’intervention",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Recommandation conditionnelle contre l'intervention",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Recommandation conditionnelle soit pour l’intervention ou pour la comparaison",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Recommandation conditionnelle pour l'intervention",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Recommandation forte pour l’intervention",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "recommendationTypeOption": {
          "content": "",
          "description": "Type de recommandation",
          "options": [
            {
              "direction": "negative",
              "text": "Recommandation forte contre l’option",
              "value": "recommend_against"
            },
            {
              "direction": "negative",
              "text": "Recommandation conditionnelle contre l'option",
              "value": "suggest_against"
            },
            {
              "direction": "none",
              "text": "Recommandation conditionnelle soit pour l’option ou la comparaison",
              "value": "suggest_either"
            },
            {
              "direction": "positive",
              "text": "Recommandation conditionnelle pour l'option",
              "value": "suggest"
            },
            {
              "direction": "positive",
              "text": "Recommandation forte pour l’option",
              "value": "recommend"
            }
          ],
          "selectedOption": ""
        },
        "researchPriorities": {
          "content": "",
          "description": "Priorités de recherche"
        },
        "restrictions": {
          "content": "",
          "description": "Restrictions"
        },
        "subgroupConsiderations": {
          "content": "",
          "description": "Considérations relatives aux sous-groupes"
        },
        "theMessageIsNecessary": {
          "content": "",
          "description": "Le message est nécessaire au sujet de la pratique réelle des soins de santé",
          "options": [
            {
              "text": "Oui",
              "value": "yes"
            },
            {
              "text": "Probablement oui",
              "value": "probably_yes"
            },
            {
              "text": "Probablement non",
              "value": "probably_no"
            },
            {
              "text": "Non",
              "value": "no"
            }
          ],
          "selectedOption": ""
        }
      }
    },
    "presentations": {
      "sections": {
        "clinicians": {
          "name": "Cliniciens",
          "sections": {
            "background": {
              "name": "Contexte"
            },
            "detailedJustification": {
              "name": "Justification détaillée"
            },
            "implementationConsiderations": {
              "name": "Considérations relatives à la mise en œuvre"
            },
            "justification": {
              "name": "Justification"
            },
            "relatedRecommendations": {
              "name": "Recommandations associées"
            },
            "subgroupConsiderations": {
              "name": "Considérations relatives aux sous-groupes"
            },
            "summaryOfFindings": {
              "name": "Synthèse des résultats"
            }
          },
          "sectionsOrder": []
        },
        "patients": {
          "name": "Patients",
          "sections": {
            "relatedRecommendations": {
              "name": "Recommandations associées"
            },
            "summaryOfFindings": {
              "name": "SoF"
            },
            "whatItMeansForYou": {
              "name": "Qu'est-ce que cela signifie pour vous?",
              "parts": {
                "speakWithHCProfessional": {
                  "additionalFields": [
                    {
                      "content": ""
                    },
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "Parler à un professionnel de santé"
                },
                "whatYouCanDo": {
                  "content": "",
                  "name": "Ce que vous pouvez faire"
                }
              }
            },
            "whoIsThisFor": {
              "name": "A qui s'adresse-t-elle?",
              "parts": {
                "whoIsThisFor": {
                  "additionalFields": [
                    {
                      "content": ""
                    }
                  ],
                  "content": "",
                  "name": "A qui s'adresse-t-elle?"
                }
              }
            },
            "whyThisRecommendation": {
              "name": "Pourquoi cette recommandation?",
              "parts": {
                "additionalInformation": {
                  "content": "",
                  "name": "Informations supplémentaires"
                },
                "benefitsAndHarms": {
                  "content": "",
                  "name": "Avantages et Préjudices"
                },
                "whyThisRecommendation": {
                  "content": "",
                  "name": "Pourquoi cette recommandation?"
                }
              }
            }
          },
          "sectionsOrder": []
        },
        "policymakers": {
          "name": "Décideurs politiques",
          "sections": {
            "assessment": {
              "name": "Évaluation"
            },
            "background": {
              "description": "Détails de la question pour la recommandation",
              "details": {
                "name": "À propos de cette décision",
                "sections": {
                  "date": {
                    "content": "",
                    "description": "Date"
                  },
                  "decisionMakers": {
                    "content": "",
                    "description": "Décideurs"
                  },
                  "perspective": {
                    "content": "",
                    "description": "Perspective"
                  },
                  "setting": {
                    "content": "",
                    "description": "Contexte"
                  }
                },
                "sectionsOrder": ["setting", "perspective", "decisionMakers", "date"]
              },
              "name": "Contexte"
            },
            "decision": {
              "content": "",
              "name": "Décision",
              "options": [
                {
                  "text": "Mise en œuvre complète",
                  "value": "full_implementation"
                },
                {
                  "text": "Évaluation de l'impact",
                  "value": "impact_evaluation"
                },
                {
                  "text": "Étude Pilote",
                  "value": "pilot_study"
                },
                {
                  "text": "Reportez",
                  "value": "postpone"
                },
                {
                  "text": "Ne mettez pas en œuvre",
                  "value": "do_not_implement"
                }
              ],
              "selectedOption": ""
            },
            "implementation": {
              "name": "Mise en œuvre"
            },
            "justification": {
              "name": "Justification"
            },
            "monitoringAndEvaluation": {
              "name": "Suivi et Évaluation"
            },
            "relatedRecommendations": {
              "name": "Recommandations associées"
            },
            "summaryOfFindings": {
              "name": "Synthèse des résultats"
            }
          },
          "sectionsOrder": []
        }
      },
      "sectionsOrder": []
    },
    "question": {
      "docId": "",
      "sections": {
        "age": {
          "content": "",
          "name": "Âge"
        },
        "anticipatedOutcomes": {
          "content": "",
          "name": "Résultats prévus"
        },
        "background": {
          "content": "",
          "name": "Contexte"
        },
        "coi": {
          "content": "",
          "name": "Conflit d'intérêts"
        },
        "comparison": {
          "content": "",
          "name": "Comparaison"
        },
        "intent": {
          "content": "",
          "name": "Objectif"
        },
        "intervention": {
          "content": "",
          "name": "Intervention"
        },
        "linkedTreatments": {
          "content": "",
          "name": "Traitements liés"
        },
        "mainOutcomes": {
          "content": "",
          "name": "Principaux résultats"
        },
        "option": {
          "content": "",
          "name": "Option"
        },
        "perspective": {
          "content": "",
          "name": "Perspective"
        },
        "population": {
          "content": "",
          "name": "Population"
        },
        "problem": {
          "content": "",
          "name": "Problème"
        },
        "purpose": {
          "content": "",
          "name": "Objectif du test"
        },
        "requirements": {
          "content": "",
          "name": "Requirements"
        },
        "role": {
          "content": "",
          "name": "Rôle du test"
        },
        "setting": {
          "content": "",
          "name": "Contexte"
        },
        "subgroups": {
          "content": "",
          "name": "Sous-groupes"
        }
      },
      "sectionsOrder": [],
      "type": ""
    }
  };
};
